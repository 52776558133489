import React from 'react'

function MyStory() {
    return (
        <>
        <h1>Hi, I'm Dannon.</h1>
        <p>I am terrible at tracking money. I am good at being cheap. Sorry, I mean frugle.</p>
        <p>I, perhaps like you, had a decent job making decent money but the problem was I could see more of it leaving the bank account than coming in.</p>
        <p>I tried many times to create and start a budget. I would try tracking reciepts. </p>
        <p>The problem is it wouldn't last. I would lose focus after a few weeks or a month. I just couldn't do it.</p>
        <p>I tried to engage with my wife but "budget" was another curse word in her book.</p>
        <p>I needed a system that I, I mean we, could stick to. I came up with Styl.</p>
        <p>Styl was designed for those who struggle month to month to know where they are spending money.</p>
        <p>I was using a popular budgeting app on my phone. It was free. It was automated. It wasn't helping.</p>
        <p>Automation in life is good but it can make you lazy.</p>
        <p>Styl is designed to make you work with your money. You've already made it but it is leaving faster than a blink of an eye.</p>
        <p>Meticulously track you spending habits and find that leak in your account. Be careful. It could be you and not your spouse (speaking from experience).</p>
        </>
    )
}

export default MyStory
