import { Routes, Route } from 'react-router-dom';
import App from './App';
import Downloads from './Pages/Downloads';
import Home from './Pages/Home';
import HowItWorks from './Pages/HowItWorks';
import MyStory from './Pages/MyStory';


export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="/" element={<Home />}></Route>
                <Route path="/mystory" element={<MyStory />}></Route>
                <Route path='/howitworks' element={<HowItWorks />}></Route>
                <Route path='/downloads' element={<Downloads />}></Route>
            </Route>
        </Routes>
    )
}