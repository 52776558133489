import React from "react";
import { Container, Row, ListGroup, Stack, Col } from "react-bootstrap";
import { BsCalendar2Month } from "react-icons/bs";
import {AiOutlineArrowRight} from 'react-icons/ai';
import "./CSS/HowItWorks.css";

function HowItWorks() {
  return (
    <Container fluid>
      <Row id="getting-started-header">
        <h1 id="getting-started-header-text">Getting Started</h1>
      </Row>
      
      <Row id="monthly-meetings-row">
        <Row>
          <Col id="monthly-meetings-row-calendar-icon" xs>
            <BsCalendar2Month size={140} />
          </Col>
          <Col id="monthly-meeting-header-text">
            <h1 id="MonthlyMeetings">Monthly Budget Meetings</h1>
          </Col>
        </Row>
        <Row id="monthly-budet-text">
          <p >Create a budget for the month. Do before the month has started.</p>
          <p>
            It is best to gather the last 6 months worth of bank statements to get an idea of what budget groups you will need.
          </p>
          <p>Calculate, on average, how much money you will bring in that month.</p>
          <p>Budget Group ideas:</p>
          <ListGroup id="budget-group-idea-list">
            <ListGroup.Item className="budget-group-idea-item"><AiOutlineArrowRight /> Housing</ListGroup.Item>
            <ListGroup.Item className="budget-group-idea-item"><AiOutlineArrowRight /> Electic Bill</ListGroup.Item>
            <ListGroup.Item className="budget-group-idea-item"><AiOutlineArrowRight /> Water Bill</ListGroup.Item>
            <ListGroup.Item className="budget-group-idea-item"><AiOutlineArrowRight /> Groceries</ListGroup.Item>
            <ListGroup.Item className="budget-group-idea-item"><AiOutlineArrowRight /> Tithing</ListGroup.Item>

          </ListGroup>
          
          <p>Based on your bank statement assessment, assign a money amount to each budget group.</p>
        </Row>
      </Row>
      <h1 id="WeeklyMeetings">Weekly Meetings</h1>
      <p>
        Set aside some time to meet with your spouse. It could take several
        hours to do this. It is worth the time investment.
      </p>
      <Stack gap={5}>
        <div id="monthly-snap-shot">
          <h3>Monthly Snap Shot</h3>
          <p>
            Each Spouse Takes turns for the month. They are to provide a monthly
            monitary snapshot
          </p>
          <p>The snapshot will include:</p>
          <ListGroup as="ol" numbered>
            <ListGroup.Item>Shows Income</ListGroup.Item>
            <ListGroup.Item>
              Shows Expendeture with their categories pulled from reciepts and
              bank statements
            </ListGroup.Item>
            <ListGroup.Item>Summary aka cashflow</ListGroup.Item>
          </ListGroup>
        </div>
        <div id="accountability">
          <h3>Spending Accountability</h3>
          As a partnership, go over the reciepts. Accountability is key!
        </div>
        <div id="shopping-accountability">
          <h3>Shopping Accountability</h3>
          <p>
            Compare the shopping list that was created at the last weekly
            meeting and was is on the actual reciepts
          </p>
          <p>
            Add any extra things purchased to your monthly Extra Shtuff list.
            For each item, mark if they are:
          </p>
          <ListGroup>
            <ListGroup.Item>
              <b>Want</b>: have a desire to possess or do (something); wish for.
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Need</b>: require (something) because it is essential or very
              important.{" "}
            </ListGroup.Item>
          </ListGroup>
          * It is important to be honest to yourself and your spouse
        </div>
        <div id="meal-planning">
          <h3>Meal Planning</h3>
          <p>
            Create a meal list. Your plan is up to you. plan for a week. pick a
            day during the week to do the shopping.
          </p>
          <p>Outcome of this planning:</p>
          <ListGroup>
            <ListGroup.Item>
              Meal List added to a calendar so it doesn't get lost.
            </ListGroup.Item>
            <ListGroup.Item>
              Shopping List in deatil. Plan for what you will need for the meals
              above as well as things you may anticipate you will need like
              items for school lunches or things used on a daily basis like milk
              and cereal.
            </ListGroup.Item>
          </ListGroup>
          *Both must sign off on the list.
        </div>
        <div id="tough-questions">
          <h3>Tough Questions</h3>
          <p>This is where things can get tricky. </p>
          <p>Have honest discussions about the week.</p>
          <ListGroup>
            <ListGroup.Item>What went well?</ListGroup.Item>
            <ListGroup.Item>What didn't go well?</ListGroup.Item>
            <ListGroup.Item>What could be improved on it?</ListGroup.Item>
            <ListGroup.Item>
              How is your month going? If not well, what can you do to get back
              on track?
            </ListGroup.Item>
            <ListGroup.Item>
              Do you see places where you can cut costs?
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Stack>
      <h1 id="EveryDay">EveryDay</h1>
      <p>
        Save Reciepts from shopping in the weekly envelope. You won't use them
        during the week. They will come in handy for the weekly meetings with
        your spouse.
      </p>
    </Container>
  );
}

export default HowItWorks;
