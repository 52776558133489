import React from "react";
import {Link} from 'react-router-dom';
import {Navbar, Container, Nav} from 'react-bootstrap';

function Header() {
  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">Stick To Your List</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/mystory" >My Story</Nav.Link>
            <Nav.Link as={Link} to="/howitworks" >How It Works</Nav.Link>
            {/* <NavDropdown title="How It Works" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/howitworks">Getting Started</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#MonthlyMeetings">Monthly Meetings</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#WeeklyMeetings">Weekly Meetings</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#EveryDay">EveryDay</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/downloads">Downloads</NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link href="#link">Store</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
