import React from "react";
import { ListGroup } from "react-bootstrap";

function Downloads() {
  return (
    <>
      <h1>Downloads</h1>
      <p>Offer some of the items below for free. Offer a pdf version of everything for a cost on the store page. A fully fleged book version may be good to offer as well.</p>
      <p>Can create a yearly work book. Includes:</p>
      <ListGroup>
        <ListGroup.Item>blank shopping list</ListGroup.Item>
        <ListGroup.Item>monthly/weekly envelope for reciepts</ListGroup.Item>
        <ListGroup.Item>monthly meal planning sheets</ListGroup.Item>
        <ListGroup.Item>monthly budget sheet</ListGroup.Item>
        <ListGroup.Item>excel spreadsheet with examples</ListGroup.Item>
        <ListGroup.Item>Extra Shtuff list</ListGroup.Item>
        <ListGroup.Item>Recipes and where to find them</ListGroup.Item>
        <ListGroup.Item>how to save money tips</ListGroup.Item>
        <ListGroup.Item>
          discussion questions for spousal meetings (the hard questions)
        </ListGroup.Item>
      </ListGroup>
    </>
  );
}

export default Downloads;
