import React from "react";
import { Container } from "react-bootstrap";

function Home() {
  return (
    <Container Fluid>
      <h1>Home Page</h1>
      <p>
        Have a better grasp of where your money is going. Don't be causual about
        your money. Don't let your money control you. You can control your
        money. We aren't here to blame one another. We are here to build unity,
        trust, and build our wealth for a better future.{" "}
      </p>
      <p>
        Ideally, couples will be able to get a grasp of where their money is
        going. This is for those who can't keep a budget. You have to work for
        it.
      </p>
      <p>
        get off of credit cards. It is hard to budget with a credit card. they
        are normally paid off a 1.5 months after a transaction is made. Live
        with money now. Feel the pain.
      </p>
    </Container>
  );
}

export default Home;
